
const AUTO_PRINT = 'print_auto_print';
const VERSION_KEY = 'VERSION_KEY';
const LOGIN_KEY = 'LOGIN_KEY';
const DISPO_KEY = 'disponibilite_status';
const CATALOG_DATA_KEY = 'CATALOG_DATA_KEY';
const PARTNER_KEY = 'PARTNER_KEY';
const USER_KEY = 'user';
const DEBUG_KEY = 'DEBUG_KEY';
const DISPO_STATUS = {
    dispo: 'dispo',
    indispo: 'indispo'
}
const saveAuth = (data) => {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(USER_KEY, JSON.stringify(data));
}

const saveUser = (user) => {
    if (typeof window === 'undefined') return;
    const userStr = window.localStorage.getItem(USER_KEY);
    if (userStr) {
        const savedObj = JSON.parse(userStr)
        savedObj.user = user;
        window.localStorage.setItem(USER_KEY, JSON.stringify(savedObj));
    }
}

const getToken = () => {
    if (typeof window === 'undefined') return;
    const user = window.localStorage.getItem(USER_KEY);
    if (!user) return '';
    return JSON.parse(user).jwt;
}

const getUser = () => {
    if (typeof window === 'undefined') return;
    const user = window.localStorage.getItem(USER_KEY);
    if (!user) return '';
    return JSON.parse(user).user;
}

const clearAuth = () => {
    if (typeof window === 'undefined') return;
    window.localStorage.removeItem(USER_KEY);
}


const getAutoPrint = () => {
    if (typeof window === 'undefined') return;
    const val = window.localStorage.getItem(AUTO_PRINT);
    return val === undefined || val === null ? true :
        val === 'false' ? false :
            val === 'true' ? true :
                val;
}

const setDisponibility = (value) => {
    if (typeof window === 'undefined') return;
    let targetValue = value ? DISPO_STATUS.dispo : DISPO_STATUS.indispo;
    window.localStorage.setItem(DISPO_KEY, targetValue);
}

const getDisponibility = () => {
    if (typeof window === 'undefined') return;
    return window.localStorage.getItem(DISPO_KEY);
}

const isDispo = () => {
    return getDisponibility() !== DISPO_STATUS.indispo;
}

const setCatalogs = (value) => {
    if (typeof window === 'undefined') return;
    if (!value) return;
    window.localStorage.setItem(CATALOG_DATA_KEY, JSON.stringify(value));
}

const getCatalogs = () => {
    if (typeof window === 'undefined') return;
    let obj = window.localStorage.getItem(CATALOG_DATA_KEY);
    return JSON.parse(obj);
}

const getPartner = () => {
    if (typeof window === 'undefined') return;
    let obj = window.localStorage.getItem(PARTNER_KEY);
    return JSON.parse(obj);
}

const setPartner = (value) => {
    if (typeof window === 'undefined') return;
    if (!value) return;
    window.localStorage.setItem(PARTNER_KEY, JSON.stringify(value));
}

module.exports = {
    getToken,
    getUser,
    saveAuth,
    clearAuth,
    saveUser,
    getAutoPrint,
    setDisponibility,
    isDispo,
    setCatalogs,
    getCatalogs,
    getPartner,
    setPartner,
    clearToken: () => {
        if (typeof window === 'undefined') return;
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.removeItem(PARTNER_KEY);
        window.localStorage.removeItem(CATALOG_DATA_KEY);
        window.localStorage.removeItem(DISPO_KEY);
        window.localStorage.removeItem(AUTO_PRINT);
        window.localStorage.removeItem(VERSION_KEY);
    },
    setVersion: (value) => {
        if (typeof window === 'undefined') return;
        window.localStorage.setItem(VERSION_KEY, value);
    },
    getVersion: () => {
        if (typeof window === 'undefined') return;
        return window.localStorage.getItem(VERSION_KEY);
    },
    saveLogin: (login) => {
        if (typeof window === 'undefined') return;
        window.localStorage.setItem(LOGIN_KEY, JSON.stringify(login));
    },
    getLogin: () => {
        if (typeof window === 'undefined') return;
        let login = window.localStorage.getItem(LOGIN_KEY);
        return login ? JSON.parse(login) : {};
    },
    setDebug: (field, value) => {
        let objStr = window.localStorage.getItem(DEBUG_KEY);
        const obj = objStr ? JSON.parse(objStr) : {};
        obj[field] = value;
        window.localStorage.setItem(DEBUG_KEY, JSON.stringify(obj));
    },
    getDebug: () => {
        let objStr = window.localStorage.getItem(DEBUG_KEY);
        return objStr ? JSON.parse(objStr) : {};
    },
    LAST_RUN_FETCH_AT: "LAST_RUN_FETCH_AT",
    LAST_FETCH_AT: "LAST_FETCH_AT"
}